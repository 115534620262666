
.hero {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.hero__logo-box {
    position: absolute;
    top: 2rem;
    left: 2rem;
    height: 10rem;
    z-index: 10;
}

.hero__logo {
    max-height: 100%;
    max-width: 100%;
}

.hero__img-box {
    position: relative;
    height: 100vh;
}

.hero__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    height: 100%;
}


@media only screen and (max-width: 48em) {
    .hero__logo-box {
        top: 6rem;
    }
}

@media only screen and (max-width: 26.5em) {
    .hero__logo-box {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 10rem;
        width: 100%;
        z-index: 10;
    }
}


.ant-carousel {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.ant-carousel .slick-list:focus {
    outline: none;
}
.ant-carousel .slick-list.dragging {
    cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
    pointer-events: none;
}
.ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
    display: table;
    content: '';
}
.ant-carousel .slick-track::after {
    clear: both;
}
.slick-loading .ant-carousel .slick-track {
    visibility: hidden;
}
.ant-carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .ant-carousel .slick-slide {
    float: right;
}
.ant-carousel .slick-slide img {
    display: block;
}
.ant-carousel .slick-slide.slick-loading img {
    display: none;
}
.ant-carousel .slick-slide.dragging img {
    pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
    display: block;
}
.ant-carousel .slick-loading .slick-slide {
    visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
    display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    color: transparent;
    background: transparent;
    outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
    opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
    opacity: 0.25;
}
.ant-carousel .slick-prev {
    left: -25px;
}
.ant-carousel .slick-prev::before {
    content: '←';
}
.ant-carousel .slick-next {
    right: -25px;
}
.ant-carousel .slick-next::before {
    content: '→';
}
.ant-carousel .slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
}
.ant-carousel .slick-dots-bottom {
    bottom: 12px;
}
.ant-carousel .slick-dots-top {
    top: 12px;
}
.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 2px;
    padding: 0;
    text-align: center;
    vertical-align: top;
}
.ant-carousel .slick-dots li button {
    display: block;
    width: 16px;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
    opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
    width: 24px;
    background: #fff;
    opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
    opacity: 1;
}
.ant-carousel-vertical .slick-dots {
    top: 50%;
    bottom: auto;
    width: 3px;
    height: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
    left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
    right: 12px;
}
.ant-carousel-vertical .slick-dots li {
    margin: 0 2px;
    vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px;
}