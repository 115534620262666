body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}


@media only screen and (max-width: 90em) {
  html {
    font-size: 48%;
  }
}

@media only screen and (max-width: 48em) {
  html {
    font-size: 62.5%;
  }
}

body {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  font-family: Camphor, Roboto, sans-serif;
}

@font-face {
  font-family: Camphor;
  src: url('./assets/camphor/regular.eot') format('eot');
  src: url('./assets/camphor/regular.eot?') format('eot'),
  url('./assets/camphor/regular.woff') format('woff'),
  url('./assets/camphor/regular.ttf') format('truetype');
}
