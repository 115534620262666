
.navigation {
    list-style: none;
    color: #fafafa;
}

.navigation__item {
    text-transform: uppercase;
    display: inline-block;
    font-size: 1.45rem;
    padding: 2rem 4rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform .4s ease, color .4s;
}

.navigation__item:hover {
    color: #B5B5B5;
    transform: translateX(8px);
}

@media only screen and (max-width: 48em) {
    .navigation__item {
        padding: 2rem;
    }
}
