
.spread-navigation {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #242021;
    margin: 0 auto;
    width: 100%;
    height: 6rem;
    border-radius: 0;
    opacity: 1;
    transition: width .4s, border-radius .4s, bottom .4s, opacity .4s;
    transform: translateX(-50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, .4);
}

.spread-navigation__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spread-navigation__link {
    padding: 1rem;
}

.spread-navigation__link:not(:last-child) {
    margin-right: 2rem;
}

.spread-navigation__link:hover .spread-navigation__icon {
    fill: #B5B5B5;
}

.spread-navigation__icon {
    height: 2.5rem;
    width: 2.5rem;
    fill: #fafafa;
    transition: width .4s, height .4s;
    transition-delay: .4s;
}

.spread-navigation__spread-button {
    height: 4rem;
    width: 4rem;
    transition: width .4s, height .4s;
}

.spread-navigation__spread-button__icon {
    height: 100%;
    width: 100%;
    filter: brightness(1) invert(1);
}

.spread-navigation--minimized {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    bottom: 2rem;
    transition-delay: .4s;
    opacity: .6;
}

.spread-navigation--minimized .spread-navigation__spread-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2rem;
    width: 2rem;
}

.spread-navigation--minimized .spread-navigation__icon {
    height: 0;
    width: 0;
    transition-delay: 0s;
}

.spread-navigation--minimized .spread-navigation__link {
    padding: 0;
}

.spread-navigation--minimized .spread-navigation__link:not(:last-child) {
    margin-right: 0;
}
