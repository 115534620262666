
.display {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.display__content {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #fafafa;
    animation: slideInRight .6s ease-out forwards;
    box-shadow: 0 0 5rem rgba(0, 0, 0, .4);
    overflow: auto;
    background-image: radial-gradient(#333, #242021 70%);
    background-size: cover;
}

.display__content--closed {
    animation: slideOutRight .6s ease-out forwards;
}

.display__icon-box {
    position: absolute;
    top: 2rem;
    right: 4rem;
    cursor: pointer;
    padding: 2rem;
    opacity: .6;

}

.display__icon-box::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 100%;
    width: 100%;
    border: 1px solid #fafafa;
    transition: transform .6s;
}

.display__icon-box:hover::after {
    transform: translate(-50%, -50%) rotate(225deg);
}

.display__icon-box:hover {
    opacity: .8;
}

.display__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2rem;
    width: 2rem;
    filter: brightness(1) invert(1);
}


@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes slideInBottom {
    0% {
        transform: translateY(100%) scale(.8);
    }

    80% {
        transform: translateY(20%) scale(.8);
    }

    100% {
        transform: translateY(0) scale(1);
    }
}

@keyframes slideOutTop {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}


@media only screen and (max-width: 26.5em) {
    .display__content {
        animation: slideInBottom .6s ease-out forwards;
    }

    .display__content--closed {
        animation: slideOutTop .6s ease-out forwards;
    }

    .display__icon-box {
        right: 2rem;
        padding: 1rem;
    }

}
