
.app {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.app__main {
  height: 94vh;
  overflow: auto;
  position: relative;
}

.app__navigation {
  z-index: 11;
}

.app__navigation__desktop {
  display: flex;
  align-items: center;
  height: 6vh;
  padding: 0 5rem;
  background-color: #242021;
  box-shadow: 0 0 2rem rgba(0,0,0,.5);
}

.app__navigation__mobile {
  display: none;
}

.app__display {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.app__content {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
}

.app__translation-switch {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 48em) {
  .app__navigation__desktop {
    padding: 0 2rem;
  }
}


@media only screen and (max-width: 26.5em) {
  .app__main {
    height: 100%;
  }

  .app__navigation__desktop {
    display: none;
  }

  .app__navigation__mobile {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    z-index: 20;
  }

  .app__content {
    height: initial;
    padding-bottom: 6rem;
  }
}
