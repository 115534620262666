
.translation-switch {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #242021;
    border-bottom-left-radius: 15px;
}

.translation-switch__language {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.4rem;
    color: #fafafa;
    cursor: pointer;
}

.translation-switch__language:not(:last-child) {
    margin-right: 2rem;
}

.translation-switch__icon {
    width: 2rem;
}
